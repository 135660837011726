import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const DateLa = (value) => {
  const year = value.getFullYear();
  const month = value.getMonth() + 1;
  const day = value.getDate();

  const dateString = `${year}-${month}-${day}`;
  return dateString;
};

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tickets: [],
  caisse:[],
  rapports: [],
  rapportCustomer:[],
  compeRendu:[],
  prodreps: [],
  prodFour: [],
  appFiche: null,
  appGain: null,
  appGpc: null,
  tracbilite:[],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TICKETS
    getTicketsSuccess(state, action) {
      state.isLoading = false;
      state.tickets = action.payload;
    },

    getCompterenduSuccess(state, action) {
      state.isLoading = false;
      state.compeRendu = action.payload;
    },
    // GET TICKETS
      getCaisseSuccess(state, action) {
        state.isLoading = false;
        state.caisse = action.payload;
      },
      getTracabiliteSuccess(state, action) {
        state.isLoading = false;
        state.tracabilite = action.payload;
      },
    // GET RAPPORTS
    getRapportsSuccess(state, action) {
      state.isLoading = false;
      state.rapports = action.payload;
    },

    getRapportCustomersSuccess(state, action) {
      state.isLoading = false;
      state.rapportCustomer = action.payload;
    },


    // GET RAPPORTS
    getProdRepsSuccess(state, action) {
      state.isLoading = false;
      state.prodreps = action.payload;
    },

    getProdFourSuccess(state, action) {
      state.isLoading = false;
      state.prodFour = action.payload;
    },

    // GET APP FICHE
    getAppFicheSuccess(state, action) {
      state.isLoading = false;
      state.appFiche = action.payload;
    },

    // GET APP GAIN
    getAppGainSuccess(state, action) {
      state.isLoading = false;
      state.appGain = action.payload;
    },

    // GET APP GPC
    getAppGpcSuccess(state, action) {
      state.isLoading = false;
      state.appGpc = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTickets(startDate, endDate, employeeId, storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/tickets', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          employee_id: employeeId,
          store_id: storeId,
        },
      });
      dispatch(slice.actions.getTicketsSuccess(response.data));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getEtatCaisses( startDate,endDate,storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/etat-caisse', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          store_id: storeId,
        },
      });
      console.log('response data',response.data)
      dispatch(slice.actions.getCaisseSuccess(response.data));
      dispatch(slice.actions.getTracabiliteSuccess(response.data));
     
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export async function createCashREgister(data) {
  const response = await axios.post(`/api/company/add-cash-regist`, data);
  return response.data;
}



export async function deleteCashRegister(id) {
  const response = await axios.delete(`/api/company/delete-cash-regist?tracabiliteId=${id}`);
  return response.data;
}

export function getCompterendu(startDate, endDate, storeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/compte-rendu', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          
          store_id: storeId,
          
        },
      });
      console.log('response comptrendu',response.data)
      dispatch(slice.actions.getCompterenduSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getRapports(startDate, endDate, employeeId, storeId, option) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/rapports', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          employee_id: employeeId,
          store_id: storeId,
          option,
        },
      });
      dispatch(slice.actions.getRapportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRapportCustomers(startDate, endDate, customerId, storeId, option) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/rapport-customers', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          customer_id: customerId,
          store_id: storeId,
          option,
        },
      });
      dispatch(slice.actions.getRapportCustomersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getProdReps(startDate, endDate, employeeId, storeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/rapports-prods', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          employee_id: employeeId,
          store_id: storeId,
        },
      });
      dispatch(slice.actions.getProdRepsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFournReps(startDate, endDate, fournisorId, storeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/company/rapports-fourn', {
        params: {
          start_date: DateLa(startDate),
          end_date: DateLa(endDate),
          fournisor_id: fournisorId,
          store_id: storeId,
        },
      });
      dispatch(slice.actions.getProdFourSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppFiche() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/company/appFiche`);
      dispatch(slice.actions.getAppFicheSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppGain() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/company/appGain`);
      dispatch(slice.actions.getAppGainSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        window.location.reload();
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAppGpc() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/company/appGpc`);
      dispatch(slice.actions.getAppGpcSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (error?.response?.status === 401) {
        delete axios.defaults.headers.common.Authorization;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        window.location.reload();
      }
    }
  };
}

export async function deleteTicket(ticketId) {
  const response = await axios.delete(`/api/company/delete-ticket?ticketId=${ticketId}`);
  return response.data;
}

export async function completedTicket(ticketId) {
  const response = await axios.put(`/api/company/completed-ticket?ticketId=${ticketId}`);
  return response.data;
}



export async function getFiche(Id) {
  const response = await axios.get(`/api/company/find-ticket-another?ref_code=${Id}`);
  return response.data;
}