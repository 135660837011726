import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import rapportReducer from './slices/rapport';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import categoryReducer from './slices/category';
import storeReducer from './slices/store';
import userReducer from './slices/user';
import countryReducer from './slices/country';
import sizeReducer from './slices/sizes';
import customerReducer from './slices/customer';
import colorReducer from './slices/colors';
import payrollReducer from './slices/payroll';
import tracabiliteReducer from './slices/tracabilite';
import reduceSalaireReducer from './slices/reduceSalaire';
import creditSalesReducer from './slices/creditSale'
import customerStoreReducer from './slices/customerStore';
import ProduitFournisseursReducer from './slices/produitFournisor'
import fournisseurStoreReducer from './slices/fournisseur'
import PaiementFournisseurReducer from  './slices/paimentproduitFourni';
import cashRegisterReducer from './slices/cashRegister';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  country: countryReducer,
  user: userReducer,
  customer: customerReducer,
  fournisseur:fournisseurStoreReducer,
  customerStore: customerStoreReducer,
  creditSales:creditSalesReducer,
  store: storeReducer,
  category: categoryReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  sizes: sizeReducer,
  colors:colorReducer,
  rapport: rapportReducer,
  payroll:payrollReducer,
  tracabilite:tracabiliteReducer,
  ProduitFournisseur:ProduitFournisseursReducer,
  PaiementFournisseur:PaiementFournisseurReducer,
  reduceSalaire:reduceSalaireReducer,
  product: persistReducer(productPersistConfig, productReducer),
  cashregister:cashRegisterReducer,
});

export { rootPersistConfig, rootReducer };
